<template>
    <div class="container-fluid">
        <!--page header start -->
        <div class="row">
            <div class="col-md-12">
                <div class="mb-2 clearfix">
                    <div class="collapse d-md-block display-options" id="displayOptions">               
                        <div class="d-block d-md-inline-block">
                            <SearchForm v-model="search" />
                        </div>
                        <div class="float-md-right d-flex">
                            <paginatorTop :offset="offset" :libelle='$t("data.occupation_loyers")' :total="occupations.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" class="mr-2 d-flex justify-content-center align-items-center" />
                            <div class="d-flex ">
                                <b-form-select class="form-control" size="sm" @input="getOccupations" v-model="periode.mois" :options="mois" />
                                <b-form-select class="form-control" size="sm" @input="getOccupations" v-model="periode.annee" :options="annees" />
                            </div>
                        </div>
                    </div>
                </div>
                
                <!--<p class="font-weight-bold" style="font-size: 22px">
                    Pour le mois de {{ $dayjs(periodeCourante).format('MMMM YYYY') }}, vous avez perçus 
                    <span style="color: #f5365c;">{{ recetteTotale | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}.</span>
                </p>-->
                <div class="d-flex justify-content-around">
                    <p class="font-weight-bold" style="font-size: 22px">
                        Pour le mois de {{ $dayjs(periodeCourante).format('MMMM YYYY') }}, vous avez perçus 
                        <span style="color: #f5365c;">{{ recetteTotale | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}.</span>
                    </p>
                    <b-button @click.prevent="exportData" size="sm"  class="not-change-image-opacity mx-1 simple-btn"><b-spinner class="ml-2" v-if="show" small></b-spinner><b-img src="@/assets/img/excel.png"></b-img> Exporter</b-button>
                </div>

                <div class="separator mb-20"></div>
                <b-overlay :show="showOverlay" rounded="sm">
                    <b-alert variant="info" class="text-center" show v-if="!occupations.length">
                        <i class="fa fa-exclamation-triangle fa-3x"></i> <br>
                        <span class="h4 d-inline-flex ml-2">Aucun dépôt n'a été enregistré à cette période</span>
                    </b-alert> 
                    <b-row v-else>
                        <div class="card-body">
                            <b-table-simple hover small responsive  sticky-header="600px" style="min-height: 20em">
                                <b-thead head-variant="light">
                                    <b-tr class="text-center">
                                        <b-th>{{$t('data.occupation_locataire')}}</b-th>
                                        <b-th>{{$t('data.occupation_logement')}}</b-th>
                                        <b-th colspan="2">Dépôts</b-th>
                                        <b-th>Total</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td></b-td>
                                        <b-td></b-td>
                                        <b-th>Montant</b-th>
                                        <b-th>Date du dépôt</b-th>
                                        <b-td></b-td>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="occupation in items" :key="occupation.idOccupation">
                                       <b-td class="p-1">
                                            <span class="d-inline-block w-100 mb-1 font-weight-bold">{{ /*occupation.locataire.titre + ' ' +*/ occupation.locataire.nomLocataire + ' ' + occupation.locataire.prenomLocataire }}</span>    
                                            <span class="d-inline-block w-100 mt-1 text-muted">{{ occupation.locataire.tel }}<span v-if="occupation.locataire.email!=null&&occupation.locataire.email!=''">{{ '/' + occupation.locataire.email }} </span></span>
                                        </b-td>
                                        <b-td class="px-2 py-1">
                                            <span class="d-inline-block w-100 mb-1 font-weight-bold">{{ occupation.logement.refLogement }}</span>    
                                            <span class="d-inline-block w-100 mt-1 text-muted">
                                                {{ occupation.logement.sousTypeLogement.libelleSousType  }}
                                                <span v-if="occupation.logement.batiment"> / {{$t("data.logement_batiment_lie_au_clonage_label")}} : {{ occupation.logement.batiment.nomBatiment }}</span>
                                            </span>    
                                        </b-td>
                                        <b-td colspan="2">
                                           <b-table-simple>
                                               <b-tr v-for="depot in occupation.depots" :key="depot.idDepot">
                                                   <b-td>{{ depot.montant | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                                                   <b-td>{{ $dayjs(depot.dateDepot).format('DD MMMM YYYY') }}</b-td>
                                               </b-tr>
                                           </b-table-simple>
                                        </b-td>
                                        <b-td>
                                            <b>{{ occupation.depots.reduce((acc, curr) => acc + curr.montant, 0) | currency(devise, 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                    </b-row>
                    <hr>
                    <paginator  v-if="trueOccupations.length > 5" :offset="offset" :total="trueOccupations.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" />
                </b-overlay>
            </div>
        </div>
    </div>
</template>
<script>
import SearchForm from "@/components/parts/SearchForm.vue";
const {mois:_mois, annees:_annees, periodeCourante:_periodeCourante} = require('../../helpers/date')

const php  = require ( 'phpjs' ) ; 
import exportFromJSON from "export-from-json";

import { mapGetters} from "vuex"
export default {
  name: "loyers",
  components: {
      SearchForm,
  },
  data: () => ({
     show:false,
     search: null,
     showOverlay: true,
     currentPage: 1,
     perPage: 10,
     occupations:[],
     trueOccupations:[],

     periode: {annee: null, mois: null},
     occupation: null,

     submitted: false,
     devise:'F',
  }),
  watch: {
       search(value) {
        this.occupations = !php.empty(value)
            ? this.trueOccupations.filter(elt =>
                elt.locataire.nomLocataire.toLowerCase().includes(value.toLowerCase()) ||
                elt.locataire.prenomLocataire.toLowerCase().includes(value.toLowerCase()) ||
                elt.logement.refLogement.toLowerCase().includes(value.toLowerCase())
            )
            : this.trueOccupations;
        },
  },
  computed: {
    ...mapGetters(['user', 'hasAccess']),
        /**
         * Elements affichés avec prise en charge de la pagination
         */
        formatedExportedData(){
            let formatedExportedData =[]
            for(let occ of this.occupations){
                let data={
                    nomLocataire:occ.locataire.nomLocataire + ' ' + occ.locataire.prenomLocataire,
                    Logement:occ.logement.refLogement,
                    montantverse:occ.depots.reduce((acc, curr) => acc + curr.montant, 0),
                    datedepot:this.$dayjs(occ.depots[0].dateDepot).format('DD MMMM YYYY')
                }
                formatedExportedData.push(data)
            }
            return formatedExportedData;
        },
        items() { 
            return php.array_slice(this.occupations.filter(elt => {
                return (this.$dayjs(this.periodeCourante).diff(elt.dateDeb, 'month') >= 0 && (php.empty(elt.dateFin) || this.$dayjs(elt.dateFin).diff(this.periodeCourante, 'month') <= 0))
            }), this.offset, this.perPage) 
        },
        offset() {
            return (this.currentPage * this.perPage) - this.perPage
        },

         mois() {
            return _mois(this.periode.annee).map(elt => ({...elt, text: php.ucfirst(elt.text)}))
        },
        periodeCourante() {
            return _periodeCourante(this.periode)
        },
        annees: () => _annees(),
        
        recetteTotale() {
            let total = 0
            for (const occupation of this.trueOccupations) {
                total += occupation.depots.reduce((acc, curr) => acc + curr.montant, 0)
            }
            return total
        }
    },

    beforeMount() {
        this.periode = {
            mois: parseInt(this.$dayjs().format('M')) - 1,
            annee: parseInt(this.$dayjs().format('YYYY'))
        }
        this.getOccupations()
    },
    mounted(){
        /*if(this.user.preferences.devise==null||this.user.preferences.devise==''){
            this.devise='F'
        }else{
           this.devise= this.user.preferences.devise
        }*/
        
    },
    methods: {
        async exportData(){
            console.log('formatteddata ',this.formatedExportedData);
            this.show=true
            try{
                const data=this.formatedExportedData
                console.log('data', data);
                const fileName = "recette_"+this.periodeCourante;
                //const exportType = exportFromJSON.types.xls;
                const exportType = exportFromJSON.types.csv;

                if (data) exportFromJSON({ data, fileName, exportType });
                setTimeout(() => {this.show=false;}, 3000);
            }catch(err){
                console.log(err.message)
            }
            this.show=false;
        },
        
    //recupération de la liste des logements
        getOccupations() {
            this.showOverlay = true
            axios.get('depots?periode='+this.periodeCourante).then(response => response.result || []).then(occupations => {
                this.occupations = this.trueOccupations = occupations.sort((a, b) => {
                    if (a.logement.refLogement < b.logement.refLogement) {
                        return -1
                    }
                    return 1
                })
                this.showOverlay = false
            });

            },
  
        generateLoyers() {
            this.showOverlay = true
            axios.post('loyers/generate', {periode: this.periodeCourante}).then(response => {
                this.showOverlay = false 
                return App.alertSuccess(response.message, {callback: () => {
                    this.getOccupations()
                }})
            }).catch(error => {
                this.showOverlay = false
                return App.alertError(error.message)
            })
        },
    }

};
</script>
<style>
 .not-change-image-opacity:hover {
    color: #82c138;
  }
  .not-change-image-opacity {
    opacity: 1;
  }
</style>